//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getAppList } from '@/api/modular/system/appManage'
import { getMenuTree, sysMenuAdd } from '@/api/modular/system/menuManage'
import IconSelector from '@/components/IconSelector'
import { sysDictTypeDropDown } from '@/api/modular/system/dictManage'
export default {
  components: { IconSelector },

  data () {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      visibleIcon: false,
      visible: false,
      confirmLoading: false,
      appData: [],
      menuTreeData: [],
      redirectShow: true,
      componentShow: true,
      componentDisabled: false,
      componentRequired: true,
      routerRequired: true,
      routerShow: true,
      iconShow: true,
      openTypeShow: true,
      pidShow: true,
      permissionShow: true,
      permissionRequired: true,
      // 圖標組件
      currentSelectedIcon: 'pause-circle',
      typeData: [],
      openTypeData: [],
      weightData: [],
      formLoading: true,
      linkShow: true,
      openTypeDisabled: false,
      openTypeDefault: [],
      openType: '',
      linkRequired: true,
      linkDisabled: false,
      type: '',
      form: this.$form.createForm(this)
    }
  },

  methods: {
    // 打開頁面初始化
    add (type) {
      this.visible = true
      // 圖標
      this.currentSelectedIcon = type

      // 默認選中菜單項，並初始化
      this.form.getFieldDecorator('type', { valuePropName: 'checked', initialValue: '1' })
      this.meneTypeFunc('1')

      // 默認選中的單選框
      // this.form.getFieldDecorator('type',{valuePropName:'checked',initialValue:'1'})
      this.form.getFieldDecorator('weight', { valuePropName: 'checked', initialValue: '2' })
      this.form.getFieldDecorator('visible', { initialValue: true })

      // 獲取系統應用列表
      this.getSysApplist()
      this.sysDictTypeDropDown()
    },

    /**
     * 獲取字典數據
     */
    sysDictTypeDropDown () {
      this.formLoading = true
      // 菜單類型
      sysDictTypeDropDown({ code: 'menu_type' }).then((res) => {
        this.typeData = res.data
      })
      // 權重
      sysDictTypeDropDown({ code: 'menu_weight' }).then((res) => {
        this.weightData = res.data
      })
      // 內外鏈
      sysDictTypeDropDown({ code: 'open_type' }).then((res) => {
        this.openTypeData = res.data
        this.formLoading = false
      })
    },

    getSysApplist () {
      return getAppList().then((res) => {
        if (res.success) {
          this.appData = res.data
        } else {
          this.$message.warning(res.message)
        }
      })
    },
    changeApplication (value) {
      getMenuTree({ 'application': value }).then((res) => {
        if (res.success) {
          this.form.resetFields(`pid`, [])
          this.menuTreeData = [{
            'id': '-1',
            'parentId': '0',
            'title': '頂級',
            'value': '0',
            'pid': '0',
            'children': res.data
          }]
        } else {
          this.$message.warning(res.message)
        }
      })
    },

    /**
     * 選擇菜單類型執行初始化表單變量
     */
    meneTypeFunc (type) {
      this.type = type
      // eslint-disable-next-line eqeqeq
      if (type == '0' || type == '1') {
        // 內外鏈地址顯示，給空值
        this.linkShow = true
        this.form.resetFields(`link`, [])
        // 圖標選擇顯示
        this.iconShow = true
        // 路由必填，設置空值，並顯示
        this.routerRequired = true
        this.form.getFieldDecorator('router', { initialValue: '' })
        this.routerShow = true
        // 權限標識框隱藏，選填，給空值
        this.permissionShow = false
        this.permissionRequired = false
        this.form.getFieldDecorator('permission', { initialValue: '' })
        // 打開方式設置為組件 ，禁用選擇方式
        this.openType = '1'
        this.form.getFieldDecorator('openType', { initialValue: this.openType = '1' })
        this.openTypeDisabled = false
      }
      // eslint-disable-next-line eqeqeq
      if (type == '0') {
        // 重定向展示，並給空
        this.redirectShow = true
        this.form.resetFields(`redirect`, [])
        // 組件默認為顯示，設置可輸入，給默認組件 PageView，驗證必填
        this.componentShow = true
        this.componentDisabled = false
        this.form.getFieldDecorator('component', { initialValue: 'PageView' })
        this.componentRequired = true
        // 父級初始化頂級，並將其隱藏
        this.form.getFieldDecorator('pid', { initialValue: '0' })
        this.pidShow = false
      } else {
        // eslint-disable-next-line eqeqeq
        if (type == '1') {
          // 組件可以手輸，取消值
          this.componentDisabled = false
          this.form.getFieldDecorator('component', { initialValue: '' })
        }
        // 重定向輸入隱藏，並給空值
        this.redirectShow = false
        this.form.getFieldDecorator('redirect', { initialValue: '' })
        // 父級選擇放開
        this.pidShow = true
      }
      // eslint-disable-next-line eqeqeq
      if (type == '2') {
        // 組件設置不填，不可輸入，並給空（手輸的跟設置的）
        this.componentRequired = false
        this.componentDisabled = true
        this.form.resetFields(`component`, [])
        this.form.getFieldDecorator('component', { initialValue: '' })
        // 路由選填，設置空值，並隱藏
        this.routerRequired = true
        this.form.getFieldDecorator('router', { initialValue: '' })
        this.routerShow = false
        // 內外鏈地址隱藏，給空值
        this.linkShow = false
        this.form.getFieldDecorator('link', { initialValue: '' })
        // 權限標識框顯示，必填，給空值
        this.permissionShow = true
        this.permissionRequired = true
        this.form.getFieldDecorator('permission', { initialValue: '' })
        // 圖標選擇隱藏,並給空
        this.iconShow = false
        this.form.getFieldDecorator('icon', { initialValue: '' })
        // 打開方式設置為無 ，禁用選擇方式
        this.openType = '0'
        this.form.getFieldDecorator('openType', { initialValue: this.openType })
        this.openTypeDisabled = true
        // 取消icon
        this.form.getFieldDecorator('icon', { initialValue: '' })
      }
      this.meneOpenTypeFunc(this.openType)
    },

    /**
     * 選擇打開方式執行方法
     */
    meneOpenTypeFunc (openType) {
       this.form.resetFields(`openType`, openType)
       // eslint-disable-next-line eqeqeq
       if (openType == '2' || openType == '3') {
         // 點擊內外鏈的時候保留原值，其他清空
         if (this.linkDisabled === false) {
           this.form.resetFields(`link`, [])
         }
          // 設置內外鏈可手輸，加驗證
         this.linkDisabled = false
         this.linkRequired = true
       } else {
          // 設置內外鏈不可手輸，取消值，取消驗證
          this.linkDisabled = true
          this.form.resetFields(`link`, [])
          this.linkRequired = false
       }
       // 另起一個分支
      // eslint-disable-next-line eqeqeq
      if (openType == '3') {
        this.componentRequired = false
        this.componentDisabled = true
        this.form.resetFields(`component`, [])
        this.form.getFieldDecorator('component', { initialValue: '' })
      } else {
        this.componentRequired = true
        // eslint-disable-next-line eqeqeq
        if (this.type == '1' || this.type == '2') {
          this.form.getFieldDecorator('component', { initialValue: '' })
        } else {
          this.form.resetFields(`component`, [])
          this.form.getFieldDecorator('component', { initialValue: 'PageView' })
        }
        // eslint-disable-next-line eqeqeq
        if (openType == '2') {
          // 組件設置為 iframe
          this.form.resetFields(`component`, [])
          this.form.getFieldDecorator('component', { initialValue: 'Iframe' })
        }
      }
      // eslint-disable-next-line eqeqeq
      if (this.type == '2') {
        // eslint-disable-next-line eqeqeq
        if (openType == '0') {
          this.componentRequired = false
          this.routerRequired = false
        }
      }
    },

    openIconSele () {
      this.visibleIcon = true
    },
    handleIconChange (icon) {
      this.form.getFieldDecorator('icon', { initialValue: icon })
      this.visibleIcon = false
    },
    handleCancelIcon () {
      this.visibleIcon = false
    },
    handleSubmit () {
      const { form: { validateFields } } = this
      this.confirmLoading = true
      validateFields((errors, values) => {
        if (!errors) {
          if (values.visible) {
            values.visible = 'Y'
          } else {
            values.visible = 'N'
          }
          sysMenuAdd(values).then((res) => {
            this.confirmLoading = false
            if (res.success) {
              this.$message.success('新增成功')
              this.$emit('ok', values)
              this.handleCancel()
            } else {
              this.$message.error('新增失敗：' + res.message)
            }
          }).finally((res) => {
            this.confirmLoading = false
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleCancel () {
      this.form.resetFields()
      this.confirmLoading = false
      this.visible = false
    }
  }

}
